* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 14px;
}

body {
  font-family: var(--font-family);
  font-weight: normal;
  background: var(--surface-ground);
  color: var(--text-color);
  /* padding: 1rem; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card {
  background: var(--surface-card);
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}

/* disables datagrid cell helighting */
.MuiDataGrid-root .MuiDataGrid-cell:focus-within, .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader {
  outline: none !important;
}

.fit-height {
  height: fit-content;
  color: #7a7c805e;
}
label.css-cmqczm-MuiFormLabel-root-MuiInputLabel-root {
  color: rgba(0, 0, 0, 0.6);
  /* font-size: auto; */
}

.css-l4u8b9-MuiInputBase-root-MuiInput-root::before {
  border-bottom: 0px !important;
}
.css-l4u8b9-MuiInputBase-root-MuiInput-root:hover:not(
    .Mui-disabled,
    .Mui-error
  ):before {
  border-bottom: 0px;
}

/* .custom-toast-container {
  font-size: inherit;
  max-width: 200px !important;
  padding: 8px;
} */

.cs-li .cs-li {
  margin-bottom: 1rem;
}

.css-ittuaa-MuiInputAdornment-root {
  margin-right: -5px !important;
}
.no-grid-lines {
  border: none !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-right: 23px !important;
}
.css-bpeome-MuiSvgIcon-root-MuiSelect-icon {
  right: 7px !important;
}
.css-bpeome-MuiSvgIcon-root-MuiSelect-icon {
  right: 5px !important;
}
